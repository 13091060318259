<template>
    <div>
        <main-header />
        <router-view />
    </div>
</template>

<style scoped lang="scss">

</style>

<script>
import MainHeader from '../../../../../components/header/MainHeader';

export default {
    name: "Loading",

    components: { MainHeader },
};
</script>
